<template>
	<div class="">
		<el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="待认领" name="first">
				<UnclaimedGoodsList v-if="'first'==activeName"></UnclaimedGoodsList>
			</el-tab-pane>
			<el-tab-pane label="已认领" name="second">
				<UnclaimedGoodsListSelf v-if="'second'==activeName"></UnclaimedGoodsListSelf>
			</el-tab-pane>
		</el-tabs>

	</div>
</template>
<script>
	import UnclaimedGoodsList from '@/components/StorageCenter/ExchangeReturn/UnclaimedGoodsList.vue';

	import UnclaimedGoodsListSelf from '@/components/StorageCenter/ExchangeReturn/UnclaimedGoodsListSelf.vue';

	export default {
		components: {
			UnclaimedGoodsList,
			UnclaimedGoodsListSelf
		},
		data() {
			return {
				activeName: 'first'
			};
		},

		activated() {

		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {},
		methods: {
			// initData() {

			// },
			handleClick(tab, event) {
				console.log(tab, event);
			}


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>